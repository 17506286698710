exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-elements-accordion-jsx": () => import("./../../../src/pages/elements/accordion.jsx" /* webpackChunkName: "component---src-pages-elements-accordion-jsx" */),
  "component---src-pages-elements-box-icon-jsx": () => import("./../../../src/pages/elements/box-icon.jsx" /* webpackChunkName: "component---src-pages-elements-box-icon-jsx" */),
  "component---src-pages-elements-box-image-jsx": () => import("./../../../src/pages/elements/box-image.jsx" /* webpackChunkName: "component---src-pages-elements-box-image-jsx" */),
  "component---src-pages-elements-box-large-image-jsx": () => import("./../../../src/pages/elements/box-large-image.jsx" /* webpackChunkName: "component---src-pages-elements-box-large-image-jsx" */),
  "component---src-pages-elements-button-jsx": () => import("./../../../src/pages/elements/button.jsx" /* webpackChunkName: "component---src-pages-elements-button-jsx" */),
  "component---src-pages-elements-call-to-action-jsx": () => import("./../../../src/pages/elements/call-to-action.jsx" /* webpackChunkName: "component---src-pages-elements-call-to-action-jsx" */),
  "component---src-pages-elements-client-logo-jsx": () => import("./../../../src/pages/elements/client-logo.jsx" /* webpackChunkName: "component---src-pages-elements-client-logo-jsx" */),
  "component---src-pages-elements-counters-jsx": () => import("./../../../src/pages/elements/counters.jsx" /* webpackChunkName: "component---src-pages-elements-counters-jsx" */),
  "component---src-pages-elements-dividers-jsx": () => import("./../../../src/pages/elements/dividers.jsx" /* webpackChunkName: "component---src-pages-elements-dividers-jsx" */),
  "component---src-pages-elements-flexible-image-slider-jsx": () => import("./../../../src/pages/elements/flexible-image-slider.jsx" /* webpackChunkName: "component---src-pages-elements-flexible-image-slider-jsx" */),
  "component---src-pages-elements-google-map-jsx": () => import("./../../../src/pages/elements/google-map.jsx" /* webpackChunkName: "component---src-pages-elements-google-map-jsx" */),
  "component---src-pages-elements-gradation-jsx": () => import("./../../../src/pages/elements/gradation.jsx" /* webpackChunkName: "component---src-pages-elements-gradation-jsx" */),
  "component---src-pages-elements-lists-jsx": () => import("./../../../src/pages/elements/lists.jsx" /* webpackChunkName: "component---src-pages-elements-lists-jsx" */),
  "component---src-pages-elements-message-box-jsx": () => import("./../../../src/pages/elements/message-box.jsx" /* webpackChunkName: "component---src-pages-elements-message-box-jsx" */),
  "component---src-pages-elements-popup-video-jsx": () => import("./../../../src/pages/elements/popup-video.jsx" /* webpackChunkName: "component---src-pages-elements-popup-video-jsx" */),
  "component---src-pages-elements-pricing-box-jsx": () => import("./../../../src/pages/elements/pricing-box.jsx" /* webpackChunkName: "component---src-pages-elements-pricing-box-jsx" */),
  "component---src-pages-elements-progress-bar-jsx": () => import("./../../../src/pages/elements/progress-bar.jsx" /* webpackChunkName: "component---src-pages-elements-progress-bar-jsx" */),
  "component---src-pages-elements-progress-circle-jsx": () => import("./../../../src/pages/elements/progress-circle.jsx" /* webpackChunkName: "component---src-pages-elements-progress-circle-jsx" */),
  "component---src-pages-elements-social-networks-jsx": () => import("./../../../src/pages/elements/social-networks.jsx" /* webpackChunkName: "component---src-pages-elements-social-networks-jsx" */),
  "component---src-pages-elements-tabs-jsx": () => import("./../../../src/pages/elements/tabs.jsx" /* webpackChunkName: "component---src-pages-elements-tabs-jsx" */),
  "component---src-pages-elements-team-member-jsx": () => import("./../../../src/pages/elements/team-member.jsx" /* webpackChunkName: "component---src-pages-elements-team-member-jsx" */),
  "component---src-pages-elements-testimonials-jsx": () => import("./../../../src/pages/elements/testimonials.jsx" /* webpackChunkName: "component---src-pages-elements-testimonials-jsx" */),
  "component---src-pages-elements-timeline-jsx": () => import("./../../../src/pages/elements/timeline.jsx" /* webpackChunkName: "component---src-pages-elements-timeline-jsx" */),
  "component---src-pages-elements-typed-text-jsx": () => import("./../../../src/pages/elements/typed-text.jsx" /* webpackChunkName: "component---src-pages-elements-typed-text-jsx" */),
  "component---src-pages-homepages-appointment-jsx": () => import("./../../../src/pages/homepages/appointment.jsx" /* webpackChunkName: "component---src-pages-homepages-appointment-jsx" */),
  "component---src-pages-homepages-cybersecurity-jsx": () => import("./../../../src/pages/homepages/cybersecurity.jsx" /* webpackChunkName: "component---src-pages-homepages-cybersecurity-jsx" */),
  "component---src-pages-homepages-infotechno-jsx": () => import("./../../../src/pages/homepages/infotechno.jsx" /* webpackChunkName: "component---src-pages-homepages-infotechno-jsx" */),
  "component---src-pages-homepages-processing-jsx": () => import("./../../../src/pages/homepages/processing.jsx" /* webpackChunkName: "component---src-pages-homepages-processing-jsx" */),
  "component---src-pages-homepages-resolutions-jsx": () => import("./../../../src/pages/homepages/resolutions.jsx" /* webpackChunkName: "component---src-pages-homepages-resolutions-jsx" */),
  "component---src-pages-homepages-services-jsx": () => import("./../../../src/pages/homepages/services.jsx" /* webpackChunkName: "component---src-pages-homepages-services-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-innerpages-about-us-jsx": () => import("./../../../src/pages/innerpages/about-us.jsx" /* webpackChunkName: "component---src-pages-innerpages-about-us-jsx" */),
  "component---src-pages-innerpages-careers-jsx": () => import("./../../../src/pages/innerpages/careers.jsx" /* webpackChunkName: "component---src-pages-innerpages-careers-jsx" */),
  "component---src-pages-innerpages-case-studies-jsx": () => import("./../../../src/pages/innerpages/case-studies.jsx" /* webpackChunkName: "component---src-pages-innerpages-case-studies-jsx" */),
  "component---src-pages-innerpages-contact-us-jsx": () => import("./../../../src/pages/innerpages/contact-us.jsx" /* webpackChunkName: "component---src-pages-innerpages-contact-us-jsx" */),
  "component---src-pages-innerpages-faq-jsx": () => import("./../../../src/pages/innerpages/faq.jsx" /* webpackChunkName: "component---src-pages-innerpages-faq-jsx" */),
  "component---src-pages-innerpages-it-services-jsx": () => import("./../../../src/pages/innerpages/it-services.jsx" /* webpackChunkName: "component---src-pages-innerpages-it-services-jsx" */),
  "component---src-pages-innerpages-it-solutions-jsx": () => import("./../../../src/pages/innerpages/it-solutions.jsx" /* webpackChunkName: "component---src-pages-innerpages-it-solutions-jsx" */),
  "component---src-pages-innerpages-leadership-jsx": () => import("./../../../src/pages/innerpages/leadership.jsx" /* webpackChunkName: "component---src-pages-innerpages-leadership-jsx" */),
  "component---src-pages-innerpages-our-history-jsx": () => import("./../../../src/pages/innerpages/our-history.jsx" /* webpackChunkName: "component---src-pages-innerpages-our-history-jsx" */),
  "component---src-pages-innerpages-pricing-plan-jsx": () => import("./../../../src/pages/innerpages/pricing-plan.jsx" /* webpackChunkName: "component---src-pages-innerpages-pricing-plan-jsx" */),
  "component---src-pages-innerpages-why-choose-us-jsx": () => import("./../../../src/pages/innerpages/why-choose-us.jsx" /* webpackChunkName: "component---src-pages-innerpages-why-choose-us-jsx" */),
  "component---src-pages-ourtalk-jsx": () => import("./../../../src/pages/ourtalk.jsx" /* webpackChunkName: "component---src-pages-ourtalk-jsx" */),
  "component---src-pages-research-index-jsx": () => import("./../../../src/pages/research/index.jsx" /* webpackChunkName: "component---src-pages-research-index-jsx" */),
  "component---src-pages-resources-activities-jsx": () => import("./../../../src/pages/resources/activities.jsx" /* webpackChunkName: "component---src-pages-resources-activities-jsx" */),
  "component---src-templates-blog-author-index-jsx": () => import("./../../../src/templates/blog-author/index.jsx" /* webpackChunkName: "component---src-templates-blog-author-index-jsx" */),
  "component---src-templates-blog-category-index-jsx": () => import("./../../../src/templates/blog-category/index.jsx" /* webpackChunkName: "component---src-templates-blog-category-index-jsx" */),
  "component---src-templates-blog-date-index-jsx": () => import("./../../../src/templates/blog-date/index.jsx" /* webpackChunkName: "component---src-templates-blog-date-index-jsx" */),
  "component---src-templates-blog-list-index-jsx": () => import("./../../../src/templates/blog-list/index.jsx" /* webpackChunkName: "component---src-templates-blog-list-index-jsx" */),
  "component---src-templates-blog-tag-index-jsx": () => import("./../../../src/templates/blog-tag/index.jsx" /* webpackChunkName: "component---src-templates-blog-tag-index-jsx" */),
  "component---src-templates-case-study-index-jsx": () => import("./../../../src/templates/case-study/index.jsx" /* webpackChunkName: "component---src-templates-case-study-index-jsx" */),
  "component---src-templates-it-solution-index-jsx": () => import("./../../../src/templates/it-solution/index.jsx" /* webpackChunkName: "component---src-templates-it-solution-index-jsx" */),
  "component---src-templates-news-list-index-jsx": () => import("./../../../src/templates/news-list/index.jsx" /* webpackChunkName: "component---src-templates-news-list-index-jsx" */),
  "component---src-templates-project-jsx-content-file-path-src-data-projects-1-enhancing-security-data-quality-with-human-centric-visualization-and-machine-learning-mdx": () => import("./../../../src/templates/project.jsx?__contentFilePath=/opt/build/repo/src/data/projects/1-enhancing-security-data-quality-with-human-centric-visualization-and-machine-learning.mdx" /* webpackChunkName: "component---src-templates-project-jsx-content-file-path-src-data-projects-1-enhancing-security-data-quality-with-human-centric-visualization-and-machine-learning-mdx" */),
  "component---src-templates-project-jsx-content-file-path-src-data-projects-10-automatic-parameter-tuning-for-big-data-analytics-framework-mdx": () => import("./../../../src/templates/project.jsx?__contentFilePath=/opt/build/repo/src/data/projects/10-automatic-parameter-tuning-for-big-data-analytics-framework.mdx" /* webpackChunkName: "component---src-templates-project-jsx-content-file-path-src-data-projects-10-automatic-parameter-tuning-for-big-data-analytics-framework-mdx" */),
  "component---src-templates-project-jsx-content-file-path-src-data-projects-11-big-data-cloud-platform-mdx": () => import("./../../../src/templates/project.jsx?__contentFilePath=/opt/build/repo/src/data/projects/11-big-data-cloud-platform.mdx" /* webpackChunkName: "component---src-templates-project-jsx-content-file-path-src-data-projects-11-big-data-cloud-platform-mdx" */),
  "component---src-templates-project-jsx-content-file-path-src-data-projects-12-supporting-the-effective-use-of-security-apis-mdx": () => import("./../../../src/templates/project.jsx?__contentFilePath=/opt/build/repo/src/data/projects/12-supporting-the-effective-use-of-security-apis.mdx" /* webpackChunkName: "component---src-templates-project-jsx-content-file-path-src-data-projects-12-supporting-the-effective-use-of-security-apis-mdx" */),
  "component---src-templates-project-jsx-content-file-path-src-data-projects-2-multi-language-vulnerability-detection-mdx": () => import("./../../../src/templates/project.jsx?__contentFilePath=/opt/build/repo/src/data/projects/2-multi-language-vulnerability-detection.mdx" /* webpackChunkName: "component---src-templates-project-jsx-content-file-path-src-data-projects-2-multi-language-vulnerability-detection-mdx" */),
  "component---src-templates-project-jsx-content-file-path-src-data-projects-3-enhancing-automatic-program-repair-via-large-language-model-mdx": () => import("./../../../src/templates/project.jsx?__contentFilePath=/opt/build/repo/src/data/projects/3-enhancing-automatic-program-repair-via-large-language-model.mdx" /* webpackChunkName: "component---src-templates-project-jsx-content-file-path-src-data-projects-3-enhancing-automatic-program-repair-via-large-language-model-mdx" */),
  "component---src-templates-project-jsx-content-file-path-src-data-projects-4-maintaining-ml-4-ci-test-case-prioritization-mdx": () => import("./../../../src/templates/project.jsx?__contentFilePath=/opt/build/repo/src/data/projects/4-maintaining-ML4CI-test-case-prioritization.mdx" /* webpackChunkName: "component---src-templates-project-jsx-content-file-path-src-data-projects-4-maintaining-ml-4-ci-test-case-prioritization-mdx" */),
  "component---src-templates-project-jsx-content-file-path-src-data-projects-6-qualitative-and-quantitative-software-vulnerability-analytics-mdx": () => import("./../../../src/templates/project.jsx?__contentFilePath=/opt/build/repo/src/data/projects/6-qualitative-and-quantitative-software-vulnerability-analytics.mdx" /* webpackChunkName: "component---src-templates-project-jsx-content-file-path-src-data-projects-6-qualitative-and-quantitative-software-vulnerability-analytics-mdx" */),
  "component---src-templates-project-jsx-content-file-path-src-data-projects-7-performance-evaluation-of-big-data-analytical-frameworks-mdx": () => import("./../../../src/templates/project.jsx?__contentFilePath=/opt/build/repo/src/data/projects/7-performance-evaluation-of-big-data-analytical-frameworks.mdx" /* webpackChunkName: "component---src-templates-project-jsx-content-file-path-src-data-projects-7-performance-evaluation-of-big-data-analytical-frameworks-mdx" */),
  "component---src-templates-project-jsx-content-file-path-src-data-projects-8-design-and-implementation-of-fragmented-clouds-mdx": () => import("./../../../src/templates/project.jsx?__contentFilePath=/opt/build/repo/src/data/projects/8-design-and-implementation-of-fragmented-clouds.mdx" /* webpackChunkName: "component---src-templates-project-jsx-content-file-path-src-data-projects-8-design-and-implementation-of-fragmented-clouds-mdx" */),
  "component---src-templates-project-jsx-content-file-path-src-data-projects-9-database-configuration-workload-parametrizations-mdx": () => import("./../../../src/templates/project.jsx?__contentFilePath=/opt/build/repo/src/data/projects/9-database-configuration-workload-parametrizations.mdx" /* webpackChunkName: "component---src-templates-project-jsx-content-file-path-src-data-projects-9-database-configuration-workload-parametrizations-mdx" */),
  "component---src-templates-resource-jsx-content-file-path-src-data-resources-open-sources-mdx": () => import("./../../../src/templates/resource.jsx?__contentFilePath=/opt/build/repo/src/data/resources/open-sources.mdx" /* webpackChunkName: "component---src-templates-resource-jsx-content-file-path-src-data-resources-open-sources-mdx" */),
  "component---src-templates-resource-jsx-content-file-path-src-data-resources-our-talks-mdx": () => import("./../../../src/templates/resource.jsx?__contentFilePath=/opt/build/repo/src/data/resources/our-talks.mdx" /* webpackChunkName: "component---src-templates-resource-jsx-content-file-path-src-data-resources-our-talks-mdx" */),
  "component---src-templates-resource-jsx-content-file-path-src-data-resources-publications-mdx": () => import("./../../../src/templates/resource.jsx?__contentFilePath=/opt/build/repo/src/data/resources/publications.mdx" /* webpackChunkName: "component---src-templates-resource-jsx-content-file-path-src-data-resources-publications-mdx" */),
  "component---src-templates-search-index-jsx": () => import("./../../../src/templates/search/index.jsx" /* webpackChunkName: "component---src-templates-search-index-jsx" */),
  "component---src-templates-single-blog-index-jsx-content-file-path-src-data-blogs-2023-10-10-scm-2-ra-part-1-mdx": () => import("./../../../src/templates/single-blog/index.jsx?__contentFilePath=/opt/build/repo/src/data/blogs/2023-10-10-SCM2-RA-Part1.mdx" /* webpackChunkName: "component---src-templates-single-blog-index-jsx-content-file-path-src-data-blogs-2023-10-10-scm-2-ra-part-1-mdx" */),
  "component---src-templates-single-blog-index-jsx-content-file-path-src-data-blogs-2023-10-10-scm-2-ra-part-2-mdx": () => import("./../../../src/templates/single-blog/index.jsx?__contentFilePath=/opt/build/repo/src/data/blogs/2023-10-10-SCM2-RA-Part2.mdx" /* webpackChunkName: "component---src-templates-single-blog-index-jsx-content-file-path-src-data-blogs-2023-10-10-scm-2-ra-part-2-mdx" */),
  "component---src-templates-single-blog-index-jsx-content-file-path-src-data-blogs-2023-10-10-scm-2-ra-part-3-mdx": () => import("./../../../src/templates/single-blog/index.jsx?__contentFilePath=/opt/build/repo/src/data/blogs/2023-10-10-SCM2-RA-Part3.mdx" /* webpackChunkName: "component---src-templates-single-blog-index-jsx-content-file-path-src-data-blogs-2023-10-10-scm-2-ra-part-3-mdx" */),
  "component---src-templates-single-blog-index-jsx-content-file-path-src-data-blogs-2023-10-10-scm-2-ra-part-4-mdx": () => import("./../../../src/templates/single-blog/index.jsx?__contentFilePath=/opt/build/repo/src/data/blogs/2023-10-10-SCM2-RA-Part4.mdx" /* webpackChunkName: "component---src-templates-single-blog-index-jsx-content-file-path-src-data-blogs-2023-10-10-scm-2-ra-part-4-mdx" */),
  "component---src-templates-single-news-index-jsx-content-file-path-src-data-news-new-member-mr-hong-phuc-mdx": () => import("./../../../src/templates/single-news/index.jsx?__contentFilePath=/opt/build/repo/src/data/news/new-member-mr-hong-phuc.mdx" /* webpackChunkName: "component---src-templates-single-news-index-jsx-content-file-path-src-data-news-new-member-mr-hong-phuc-mdx" */),
  "component---src-templates-single-news-index-jsx-content-file-path-src-data-news-new-member-mr-jinyang-li-mdx": () => import("./../../../src/templates/single-news/index.jsx?__contentFilePath=/opt/build/repo/src/data/news/new-member-mr-jinyang-li.mdx" /* webpackChunkName: "component---src-templates-single-news-index-jsx-content-file-path-src-data-news-new-member-mr-jinyang-li-mdx" */),
  "component---src-templates-single-news-index-jsx-content-file-path-src-data-news-new-member-mr-zihao-mdx": () => import("./../../../src/templates/single-news/index.jsx?__contentFilePath=/opt/build/repo/src/data/news/new-member-mr-zihao.mdx" /* webpackChunkName: "component---src-templates-single-news-index-jsx-content-file-path-src-data-news-new-member-mr-zihao-mdx" */),
  "component---src-templates-single-news-index-jsx-content-file-path-src-data-news-new-member-mr-ziyang-ye-mdx": () => import("./../../../src/templates/single-news/index.jsx?__contentFilePath=/opt/build/repo/src/data/news/new-member-mr-ziyang-ye.mdx" /* webpackChunkName: "component---src-templates-single-news-index-jsx-content-file-path-src-data-news-new-member-mr-ziyang-ye-mdx" */),
  "component---src-templates-single-news-index-jsx-content-file-path-src-data-news-new-publication-a-survey-on-uav-mdx": () => import("./../../../src/templates/single-news/index.jsx?__contentFilePath=/opt/build/repo/src/data/news/new-publication-a-survey-on-uav.mdx" /* webpackChunkName: "component---src-templates-single-news-index-jsx-content-file-path-src-data-news-new-publication-a-survey-on-uav-mdx" */),
  "component---src-templates-single-news-index-jsx-content-file-path-src-data-news-new-publication-ad-hoc-blockchain-platform-mdx": () => import("./../../../src/templates/single-news/index.jsx?__contentFilePath=/opt/build/repo/src/data/news/new-publication-ad-hoc-blockchain-platform.mdx" /* webpackChunkName: "component---src-templates-single-news-index-jsx-content-file-path-src-data-news-new-publication-ad-hoc-blockchain-platform-mdx" */),
  "component---src-templates-single-news-index-jsx-content-file-path-src-data-news-new-publication-case-study-off-ubuntu-mdx": () => import("./../../../src/templates/single-news/index.jsx?__contentFilePath=/opt/build/repo/src/data/news/new-publication-case-study-off-ubuntu.mdx" /* webpackChunkName: "component---src-templates-single-news-index-jsx-content-file-path-src-data-news-new-publication-case-study-off-ubuntu-mdx" */),
  "component---src-templates-single-news-index-jsx-content-file-path-src-data-news-new-publication-metamorphic-testing-mdx": () => import("./../../../src/templates/single-news/index.jsx?__contentFilePath=/opt/build/repo/src/data/news/new-publication-metamorphic-testing.mdx" /* webpackChunkName: "component---src-templates-single-news-index-jsx-content-file-path-src-data-news-new-publication-metamorphic-testing-mdx" */),
  "component---src-templates-single-news-index-jsx-content-file-path-src-data-news-new-publication-naturalistic-phishing-simulation-mdx": () => import("./../../../src/templates/single-news/index.jsx?__contentFilePath=/opt/build/repo/src/data/news/new-publication-naturalistic-phishing-simulation.mdx" /* webpackChunkName: "component---src-templates-single-news-index-jsx-content-file-path-src-data-news-new-publication-naturalistic-phishing-simulation-mdx" */),
  "component---src-templates-single-news-index-jsx-content-file-path-src-data-news-new-publication-phishing-awareness-mdx": () => import("./../../../src/templates/single-news/index.jsx?__contentFilePath=/opt/build/repo/src/data/news/new-publication-phishing awareness.mdx" /* webpackChunkName: "component---src-templates-single-news-index-jsx-content-file-path-src-data-news-new-publication-phishing-awareness-mdx" */),
  "component---src-templates-single-news-index-jsx-content-file-path-src-data-news-new-publication-privacy-engineering-in-wild-mdx": () => import("./../../../src/templates/single-news/index.jsx?__contentFilePath=/opt/build/repo/src/data/news/new-publication-privacy-engineering-in-wild.mdx" /* webpackChunkName: "component---src-templates-single-news-index-jsx-content-file-path-src-data-news-new-publication-privacy-engineering-in-wild-mdx" */),
  "component---src-templates-single-news-index-jsx-content-file-path-src-data-news-new-publication-security-for-ai-systems-mdx": () => import("./../../../src/templates/single-news/index.jsx?__contentFilePath=/opt/build/repo/src/data/news/new-publication-security-for-ai-systems.mdx" /* webpackChunkName: "component---src-templates-single-news-index-jsx-content-file-path-src-data-news-new-publication-security-for-ai-systems-mdx" */),
  "component---src-templates-single-news-index-jsx-content-file-path-src-data-news-new-publication-security-orchestrations-mdx": () => import("./../../../src/templates/single-news/index.jsx?__contentFilePath=/opt/build/repo/src/data/news/new-publication-security-orchestrations.mdx" /* webpackChunkName: "component---src-templates-single-news-index-jsx-content-file-path-src-data-news-new-publication-security-orchestrations-mdx" */),
  "component---src-templates-single-news-index-jsx-content-file-path-src-data-news-we-are-hiring-mdx": () => import("./../../../src/templates/single-news/index.jsx?__contentFilePath=/opt/build/repo/src/data/news/we-are-hiring.mdx" /* webpackChunkName: "component---src-templates-single-news-index-jsx-content-file-path-src-data-news-we-are-hiring-mdx" */)
}

